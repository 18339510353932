import React, { useEffect, useState } from 'react';
import Slider from "react-slick";
import { getTopper } from '../Service/Api';
const TopperXII = () => { 
  const [data, setData] = useState([]);

  useEffect(() => {
      const fetchData = async () => {
          try {
              const topperData = await getTopper();
              setData(topperData);
          } catch (error) {
              console.error("Error fetching topper data:", error);
          }
      };
      fetchData();
  }, []);

  useEffect(() => {
      console.log("topper_data", data);
  }, [data]);

  const emptyArray = [
      { id:1,name:"Student Name",stream:"Stream",division:"%" },   
      { id:2 ,name:"Student Name",stream:"Stream",division:"%" },   
      { id:3,name:"Student Name",stream:"Stream",division:"%" },   
    ];

  const settings = {
    autoplay: true,
    infinite: true,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  const filteredData = data.filter(div => div.class === "XII");
  return (
    <>

      <Slider {...settings} className='10'>
      {(filteredData.length > 0 ? filteredData : emptyArray).map((item, index) => (
          <div className="item" key={item.id || index}>
          <div className="topperdiv">
            <div className="topperimages">
            <img src={item.attachments && item.attachments[0] ? `https://d280nq1n4mqyso.cloudfront.net/api/image/${item.attachments[0]}` : "https://d280nq1n4mqyso.cloudfront.net/api/image/JRPSB/public/Images/trophy.png"} className="img-fluid tooperpicicn" alt=" Jai Rani Public School, Balussery" />
        
              <p>{item.division}</p>
            </div>
            <div className="topperdesc">
              <h5>{item.name}</h5>
              <p className="per">{item.stream}</p>
            </div>
          </div>
        </div> 
        )) }
      </Slider>
    </>
  )
}

export default TopperXII