import React, { useState, useEffect } from 'react';
import Slider from "react-slick"; 
import {getPrincipalMessage} from'../Service/Api'
const Message = () => { 
const [data, setData] = useState([]);
const [loading, setLoading] = useState(true); 
console.log(data);

useEffect(() => {
    const fetchData = async () => {
        try {
            const principalData = await getPrincipalMessage();
            setData(principalData);
            
        } catch (error) {
            console.log("Data Error", error);
        } finally{
            setLoading(false)
        }
    };
    fetchData();
}, []);
const emptyData = [
   { id: 1}
];
  const settings = {
    autoplay: true,
    infinite: true,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 1,
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };
  return (
    <>
      <Slider {...settings}>
      {data.length > 0 ? data.map((item,index) => ( 
          <div className="item" key={index}>
            <div className="messagebox">              
              <div className="messagedesc"> 
              
              <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/JRPSB/public/Images/quote.png" className="img-fluid top-quote" />
              <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/JRPSB/public/Images/quote.png" className="img-fluid bottom-quote" />
                   <div className="messagedescpara">
                      <p><div dangerouslySetInnerHTML={{ __html: item.message }} /> </p>
                   </div>
              </div>
              <div className="principalimgbox">
              <p>{item.name} <span>  {item.category}</span></p>
              <img src={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item.attachments}`} alt="" className="img-fluid"/>
              </div>
            </div>
          </div>
          )) : emptyData.map((item) => (
            <div className="messagebox">              
            <div className="messagedesc"> 
            
            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/JRPSB/public/Images/quote.png" className="img-fluid top-quote" />
            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/JRPSB/public/Images/quote.png" className="img-fluid bottom-quote" />
            <p><b>“Education is the passport to the future, for tomorrow belongs to those who prepare for it today”</b> – Malcolm X</p>
            <p>I am honoured and exceptionally privileged to be the Principal of Jai Rani SUBS Public School, Balussery. We are confident and believe that God has blessed us in so many ways. We believe that each child is a special gift of God and is gifted with lot of potential. Jai Rani School imparts education to children to develop their knowledge, skills, moral values, positive thinking and the attitude of giving to the less privileged of society.</p>
           
            </div>
            <div className="principalimgbox">
            <p>Sr.Philomina Thomas <span>  Principal</span></p>
            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/JRPSB/public/Images/principal.jpg"  alt="" className="img-fluid"/>
            </div>
          </div>
            ))}
        
      </Slider>
    </>
  )
}

export default Message
