import React, {useEffect}  from "react"; 
import { Link } from 'react-router-dom'; 
import Header from '../Component/Header'
import Footer from '../Component/Footer' 

const BasketballCourt = () => { 
    useEffect(() => {
        document.title = 'Basketball Court - Jai Rani Public School, Balussery';  
    }, []);   
    return (
        <>
            <Header />
<div className="innerslide">
                <ul className="breadcrumb">
                    <li><Link to="/"> Home</Link> </li>  
                    <li>Facilities </li>
                    <li>Basketball Court</li> 
                </ul>
            </div> 
            <div className="innersec">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1>Basketball Court</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                                 
      <p>The basketball court at Jai Rani school is a state-of-the-art playing surface, featuring a rectangular floor with baskets at each end. Conveniently located behind the school, our court offers ample space for students to play and spectators to cheer. The covered basketball ground provides an ideal environment for students to develop team spirit and hone their skills through regular practice sessions.</p>


      <p>With expert coaching and guidance, our students are encouraged to develop their physical skills, strategic thinking, and sportsmanship. The court hosts intra-school tournaments, fostering healthy competition and school pride. Our basketball team has consistently demonstrated excellence, winning accolades and recognition in competitions.</p>
    
    
      <p>Beyond competitive play, the court serves as a vibrant hub for recreational activities, promoting physical fitness, camaraderie, and stress relief among students. We believe that sports play a vital role in shaping well-rounded individuals, and our basketball court is an integral part of this vision. </p>
                        </div>
                    </div> 
                </div>
            </div>
             <Footer />
        </>
    );
}

export default BasketballCourt;
