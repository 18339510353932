import React, {useEffect}  from "react"; 
import { Link } from 'react-router-dom'; 
import Header from '../Component/Header'
import Footer from '../Component/Footer' 

const Fee = () => { 
    useEffect(() => {
        document.title = 'Fee - Jai Rani Public School, Balussery';  
    }, []);   
    return (
        <>
            <Header />
<div className="innerslide">
                <ul className="breadcrumb">
                    <li><Link to="/"> Home</Link> </li>  
                    <li>Academics </li>
                    <li>Fee</li>
                </ul>
            </div> 
            <div className="innersec">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1>Fee</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <p>The school fee is fixed after considering several factors, such as the normal capacity of parents to pay, the guidelines provided by the CBSE and State government, the average pay scales offered in private schools, the need to balance income and expense of the school on an annual basis, the need for a reserve fund, the school’s contingency fund, and other similar issues. The latest infrastructure provided at the school also matters much in deciding the quantum of fees. </p>
                        </div>
                    </div> 
                </div>
            </div>
             <Footer />
        </>
    );
}

export default Fee;
