import React, {useEffect}  from "react"; 
import { Link } from 'react-router-dom'; 
import Header from '../Component/Header'
import Footer from '../Component/Footer' 

const ComputerLab = () => { 
    useEffect(() => {
        document.title = 'Computer Lab - Jai Rani Public School, Balussery';  
    }, []);   
    return (
        <>
            <Header />
<div className="innerslide">
                <ul className="breadcrumb">
                    <li><Link to="/"> Home</Link> </li>  
                    <li>Facilities </li>
                    <li>Computer Lab</li>
                </ul>
            </div> 
            <div className="innersec">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1>Computer Lab</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                           <p>Our state-of-the-art Computer Science laboratory is equipped with 42 computers, providing students with an ideal environment to explore the world of computer technology. This spacious lab is designed to foster hands-on learning, enabling students to develop essential programming skills, software applications, and digital literacy. With a computer-to-student ratio of 1:1, every student has uninterrupted access to cutting-edge technology, allowing them to practice, experiment, and innovate under the guidance of experienced instructors. Our Computer Science lab serves as a hub for coding, software development, and digital creativity, empowering students to stay ahead in today's technology-driven world.</p>
                        </div>
                    </div> 
                </div>
            </div>
             <Footer />
        </>
    );
}

export default ComputerLab;
