import React, {useEffect}  from "react"; 
import { Link } from 'react-router-dom'; 
import Header from '../Component/Header'
import Footer from '../Component/Footer' 

const ScienceLab = () => { 
    useEffect(() => {
        document.title = 'Science Lab - Jai Rani Public School, Balussery';  
    }, []);   
    return (
        <>
            <Header />
<div className="innerslide">
                <ul className="breadcrumb">
                    <li><Link to="/"> Home</Link> </li>  
                    <li>Facilities </li>
                    <li>Science Lab</li>
                </ul>
            </div> 
            <div className="innersec">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1>Science Lab</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <p>Our school takes pride in its state-of-the-art science laboratories, which provide students with a comprehensive learning experience in Physics, Chemistry, and Biology. These well-equipped labs serve as a hub for hands-on exploration and experimentation, allowing students to interact with data and concepts in a practical setting. By combining theoretical knowledge with practical application, our science labs empower students to deepen their understanding of scientific principles and their real-world applications.</p>
                        </div>
                    </div> 
                </div>
            </div>
             <Footer />
        </>
    );
}

export default ScienceLab;
