import React, {useEffect}  from "react"; 
import { Link } from 'react-router-dom'; 
import Header from '../Component/Header'
import Footer from '../Component/Footer' 

const TrustMembers = () => { 
    useEffect(() => {
        document.title = 'Trust Members - Jai Rani Public School, Balussery';  
    }, []);   
    return (
        <>
            <Header />
<div className="innerslide">
                <ul className="breadcrumb">
                    <li><Link to="/"> Home</Link> </li>  
                    <li>About </li>
                    <li>Trust Members</li>
                </ul>
            </div> 
            <div className="innersec">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1>Trust Members</h1>
                        </div>
                    </div>
                    <div className="row">
                           <div className="col-12">
                           <div className="table-responsive maintable">
                           <table className="table"> 
                               <thead>
                                <tr>
                                  <th width="200px"> S NO </th>
                                  <th width="200px"> NAME </th> 
                                  <th width="200px"> DESIGNATION </th> 
                                  <th width="200px"> AGE </th>
                                  <th width="200px"> PHONE NO </th> 
                               </tr>
                                </thead>
                                 <tbody>
                                  <tr><td>1.</td> <td>Sr. Valsamma K A</td> <td>Chairman</td> <td>59</td> <td>9207932212</td></tr> <tr><td>2.</td> <td>Sr. Lucy N C</td> <td>Member</td> <td>63</td> <td>9048008184</td></tr> <tr><td>3.</td> <td>Sr. Lisamma John</td> <td>Member</td> <td>54</td> <td>7902251535</td></tr> <tr><td>4.</td> <td>Sr. Simy Scaria</td> <td>Member</td> <td>44</td> <td>9539985678</td></tr> <tr><td>5.</td> <td>Sr. Deepa M M</td> <td>Member</td> <td>44</td> <td>9207868715</td></tr> <tr><td>6.</td> <td>Sr. Shiji N Mathew</td> <td>Member</td> <td>44</td> <td>8281531979</td></tr>
                                   </tbody> 
                               </table>
                          </div>
                          
                            </div>
                          </div>
                       </div>
                    </div>  
             <Footer />
        </>
    );
}

export default TrustMembers;
