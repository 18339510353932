import axios from "axios"
import { API_URL, getHeader } from "../config"

export const getHighlight = async() => {
    const { data } = await axios.get(API_URL + `/api/highlight/JRPSB`);
    console.log(data)
    return data;
} 

export const getNews = async() => {
    const { data } = await axios.get(API_URL + `/api/news/JRPSB`);
    console.log(data)
    return data;
} 

export const getNewsInner = async() => {
    const { data } = await axios.get(API_URL + `/api/news/All/JRPSB`);
    console.log(data)
    return data;
}
export const getEvents = async() => {
    const { data } = await axios.get(API_URL + `/api/events/JRPSB`);
    console.log(data)
    return data;
} 

export const getEventsinner = async() => {
    const { data } = await axios.get(API_URL + `/api/events/All/JRPSB`);
    console.log(data)
    return data;
}
 
export const getBirthdays = async() => {    
    const { data } = await axios.get(API_URL + `/api/birthdays/JRPSB`);
    console.log("birthday",data)
     return data;
} 

export const getGallery = async() => {
    const { data } = await axios.get(API_URL + `/api/gallery/JRPSB`);
    return data;
} 

export const getGalleryInner = async() => {
    const { data } = await axios.get(API_URL + `/api/gallery/all/JRPSB`);
    return data;
} 

export const getSubGallery = async(id) => {
    console.log(id)
    const { data } = await axios.get(API_URL + `/api/subGallery?id=${id}`);
    console.log(data)
    return data;
} 

export const getNotification = async() => {
    const { data } = await axios.get(API_URL + `/api/notification/JRPSB`,getHeader());
    return data;
} 

export const getTopper = async() => {
    const { data } = await axios.get(API_URL + `/api/topper/JRPSB`,getHeader());
    return data;
} 

export const getOnlineRegistration = async() => {
    const { data } = await axios.get(API_URL + `/api/onlineRegistration/JRPSB`,getHeader());
    return data;
} 

export const getSliders = async() => {
    const { data } = await axios.get(API_URL + `/api/slider/JRPSB`,getHeader());
    return data;
} 

export const getPrincipalMessage = async() => {
    const { data } = await axios.get(API_URL + `/api/PrincipalMessage/JRPSB`, getHeader());
   console.log(data)
    return data;
} 

  
export const postTransferCertificate = async(name,admission) => {
    console.log(name,admission)

    const { data } = await axios.post(API_URL + `/api/tc/JRPSB`,{name,admission},getHeader());
    return data;
}
export const form1 = async (formData, header, type) => {
    try {
        const { data } = await axios.post(`${API_URL}/api/form/JRPSB/${type}`, formData, header);
        console.log(data)
        return data;
    } catch (error) {
        console.error('Error in form submission:', error);
        if (error.response) {
            console.error('Error response data:', error.response.data); 
        }
        throw error;
    }
  };
  export const getVideo = async() => {
    const { data } = await axios.get(API_URL + `/api/video/All/JRPSB`);
    return data;
} 