import React, {useEffect}  from "react"; 
import { Link } from 'react-router-dom'; 
import Header from '../Component/Header'
import Footer from '../Component/Footer' 

const AtalTinkeringLab = () => { 
    useEffect(() => {
        document.title = 'Atal Tinkering Lab - Jai Rani Public School, Balussery';  
    }, []);   
    return (
        <>
            <Header />
<div className="innerslide">
                <ul className="breadcrumb">
                    <li><Link to="/"> Home</Link> </li>  
                    <li>Facilities </li>
                    <li>Atal Tinkering Lab</li>
                </ul>
            </div> 
            <div className="innersec">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1>Atal Tinkering Lab</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                        <p> Our school is  proud to house the Atal Tinkering Lab (ATL), an innovative space where students can tinker, learn, and grow. Inaugurated on 12th March 2019, the ATL lab has been providing students with hands-on experience in cutting-edge technologies, fostering creativity, innovation, and problem-solving skills. </p>
                        <h3>About Atal Tinkering Lab </h3>
                        <p> The Atal Tinkering Lab initiative is a flagship program of the Atal Innovation Mission (AIM), NITI Aayog, aimed at cultivating innovative thinking and entrepreneurship among India's youth. The lab provides a platform for students to explore, experiment, and learn through DIY (Do-It-Yourself) kits, robotics, electronics, and other emerging technologies. </p>
                        <h3>Facilities and Resources </h3>
                        <p> Our ATL lab is equipped with state-of-the-art facilities and resources, including: </p>
                        <ul className="list">
                        <li>Dedicated workspace for students to tinker and innovate </li>
                        <li>Advanced DIY kits and tools for robotics, electronics, and coding </li>
                        <li>Computers and software for programming and simulation </li>
                        <li>Expert mentors and coaches for guidance and support </li>
                        </ul>
                        <h3>Coaching and Programs </h3>
                        <p>The ATL lab offers coaching and programs for students from classes 6 to 9, focusing on: </p>
                        <ul className="list">
                        <li>Robotics and Mechatronics </li>
                        <li>Electronics and Circuit Design </li>
                        <li>Coding and Programming (Python, Arduino, etc.) </li>
                        <li>Innovation and Entrepreneurship </li>
                        <li>Project-based learning and problem-solving </li>
                        </ul>
                        <h3>Benefits for Students </h3>
                        <p> The ATL lab provides students with: </p>
                        <ul className="list">
                        <li>Hands-on experience with emerging technologies</li>
                        <li>Development of critical thinking, creativity, and problem-solving skills</li>
                        <li>Opportunities for innovation, entrepreneurship, and leadership</li>
                        <li>Enhanced STEM education and career prospects</li>
                        <li>Collaboration and teamwork skills through peer-to-peer learning</li>
                        </ul>
                        <p> The Atal Tinkering Lab at our school is a vibrant hub of innovation and creativity, empowering students to become the next generation of thinkers, makers, and leaders. We are committed to nurturing the talents of our students and providing them with the skills and knowledge required to succeed in an ever-evolving world. </p>
                        </div>
                    </div> 
                </div>
            </div>
             <Footer />
        </>
    );
}

export default AtalTinkeringLab;
