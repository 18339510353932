import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import {getNewsInner} from'../Service/Api'
import Slider from "react-slick"; 
const News = () => { 
  const [newsData, setNewsData] = useState([]);
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getNewsInner();
        setNewsData(data);
      } catch (error) {
        console.error("Failed to fetch news:", error);
        setNewsData([]); // Set to empty to ensure the empty state is handled
      }
    };
    fetchData();
  }, []);
  const emptyArray = [
      { title: "Stay Tuned For More Updates", description: "Stay Tuned For More Updates" },
      { title: "Stay Tuned For More Updates", description: "Stay Tuned For More Updates" },
      { title: "Stay Tuned For More Updates", description: "Stay Tuned For More Updates" }
  ];
    const settings = {
        autoplay: true,
        infinite: true, 
        autoplaySpeed: 2000,
        speed: 500,
        slidesToShow:2,
        slidesToScroll: 1,
        initialSlide: 0,
        arrows: false, 
        adaptiveHeight: true,
        responsive: [
          {
            breakpoint: 1400,
            settings: {
              slidesToShow: 2, 
            }
          },
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 2, 
            }
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1, 
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1, 
            }
          }
        ]
      };
    
  return (
     <>
     <Slider {...settings}>  
     {newsData.length > 0 ?  newsData.map((item, index) => { 
        const date = new Date(item.fromDate);
        const mon = monthNames[date.getMonth()];
          const eventdate = date.getDate();  
     return  (  
   
      <div className="item" key={index}>
          <div className="homeeventbox news-blk">
          <div className="homeeventboximg">
              <div className="homeeventboxdate">{eventdate} {mon}</div>
                {item.image.length >0 ? ( <img src={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item.image}`} className="img-fluid" alt="Jai Rani public School" />  ) : (<img src="https://d280nq1n4mqyso.cloudfront.net/api/image/JRPSB/public/Images/news.jpg" className="img-fluid" alt="Jai Rani public School" />)}
               
              </div> 
            <div className="homeeventboxdesc"> 
                <h5>{item.title}</h5> 
              <p>{item.description}</p>
              {item.attachments && item.attachments[0] && ( 
                <div className="homeeventboxbottom"><span> 
                <Link to={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item.attachments}`} target="_blank"><i className="bi bi-paperclip"></i></Link> 
               </span></div>
               )}
            </div>              
          </div>
        </div>  
       )}) : 
   emptyArray.map((item, index) => (
    <div className="item" key={index}>
    <div className="homeeventbox news-blk">
    <div className="homeeventboximg">
        <div className="homeeventboxdate">DD MM</div>
          <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/JRPSB/public/Images/news.jpg" className="img-fluid" alt="Jai Rani public School" />  
        </div> 
      <div className="homeeventboxdesc"> 
          <h5>{item.title}</h5> 
        <p>{item.description}</p>
        <div className="homeeventboxbottom">
        <span>
             <Link to="#" target="_blank"><i className="bi bi-paperclip"></i></Link> 
        </span>
        </div>
        
      </div>              
    </div>
  </div> 
       ))
     }
      {/*   <div className="item">
          <div className="homeeventbox news-blk">
          <div className="homeeventboximg">
              <div className="homeeventboxdate">02 Oct</div>
                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/JRPSB/public/Images/news1.png" className="img-fluid" alt="Jai Rani public School" /> 
               
              </div>
           
            <div className="homeeventboxdesc"> 
                <h5>Admission opens session 2025-26 from 18th october onwards</h5> 
              <p>Admission open session 2025-26 from 18th October onwards</p>
              <div className="homeeventboxbottom"><span><i class="bi bi-paperclip"></i></span></div>
            </div>              
          </div>
        </div>
        <div className="item">
          <div className="homeeventbox news-blk">
          <div className="homeeventboximg">
              <div className="homeeventboxdate">02 Oct</div>
                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/JRPSB/public/Images/news2.png" className="img-fluid" alt="Jai Rani public School" /> 
               
              </div>
           
            <div className="homeeventboxdesc"> 
            <h5>Supplementary Exam Datesheet Class X 2024</h5> 
              <p>Supplementary Exam Datesheet Class X 2024</p>
              <div className="homeeventboxbottom"><span><i class="bi bi-paperclip"></i></span></div>
            </div>              
          </div>
  </div>   */  }
       
     </Slider> 
     <Link to="/News"> <button className='read-more'>READ MORE</button></Link>
     </>
  )
}

export default News
