import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom' 
import Header from '../Component/Header'
import Footer from '../Component/Footer'
import {getPrincipalMessage} from'../Service/Api'
const PrincipalMessage = () => { 
    useEffect(() => {
        document.title = ' Principal -Jai Rani Public School, Balussery';  
    }, []);  
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true); 
    console.log(data);
 
    useEffect(() => {
        const fetchData = async () => {
            try {
                const principalData = await getPrincipalMessage();
                setData(principalData);
                
            } catch (error) {
                console.log("Data Error", error);
            } finally{
                setLoading(false)
            }
        };
        fetchData();
    }, []);
    const emptyData = [
       { id: 1}
   ];
  return (
     <> 
       <Header />
<div className="innerslide">
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                
                 <li>  About</li>
                 <li>Principal's  Message</li>
            </ul>
        </div> 
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>   Principal's  Message </h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                    {data.length > 0 ? data.map((item) => (
                        <div className="clearfix">
                         <img src={`https://webapi.entab.info/api/image?url=${item.attachments}`} alt="" className="col-md-4 float-md-end  left-img img-fluid"/>
                         
                          <div dangerouslySetInnerHTML={{ __html: item.message }} /> 
                         <div className="mesdeskimg"> 
                         <h3> {item.name}</h3>
                         <h5> {item.category}</h5>
                      </div>
                         </div>
                     )) : emptyData.map((item) => (
                         <div className="clearfix"> 
                         <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/JRPSB/public/Images/principal.jpg" className="col-md-4 float-md-end  left-img img-fluid"/>
                        
                                <p><b>“Education is the passport to the future, for tomorrow belongs to those who prepare for it today”</b> – Malcolm X</p>
                                <p>I am honoured and exceptionally privileged to be the Principal of Jai Rani SUBS Public School, Balussery. We are confident and believe that God has blessed us in so many ways. We believe that each child is a special gift of God and is gifted with lot of potential. Jai Rani School imparts education to children to develop their knowledge, skills, moral values, positive thinking and the attitude of giving to the less privileged of society.</p>
                                <p>The aim of our education is to open up the minds of students and prepare them to face the world.</p>
                                <p>As the principal of this esteemed institution, I urge all my students to:</p> 
                                <p>Imagine with all their mind</p>
                                <p>Believe with all their heart and</p>
                                <p>Achieve with all their might.</p>
                                <p><b>“My God will fully satisfy every need of yours according to his riches in glory in Christ Jesus” - </b> (Phil 4:19)</p>
                            <div className="mesdeskimg"> <h3>  Sr.Philomina Thomas</h3> <h5>  Principal  </h5></div>
                            </div> 
                         ))}
                    </div>
                </div>
            </div>
        </div>
         <Footer />
     </>
  )
}

export default PrincipalMessage

