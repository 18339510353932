import React, { useEffect, useState } from 'react' 
const HomeSlider = () => {
  
  return (
     <>
     <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
     <div className="carousel-inner">
        
           <div className="carousel-item active">
           <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/JRPSB/public/Images/slide1.jpg"  className="d-block w-100" alt="Jai Rani Public School, Balussery"/>
           </div>
         
     </div>
     
 </div>


     </>
  )
}

export default HomeSlider
