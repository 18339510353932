import React, {useEffect}  from "react"; 
import { Link } from 'react-router-dom'; 
import Header from '../Component/Header'
import Footer from '../Component/Footer' 

const Academics = () => { 
    useEffect(() => {
        document.title = 'Academics - Jai Rani Public School, Balussery';  
    }, []);   
    return (
        <>
            <Header />
<div className="innerslide">
                <ul className="breadcrumb">
                    <li><Link to="/"> Home</Link> </li>  
                    <li>About </li>
                    <li>Academics</li>
                </ul>
            </div> 
            <div className="innersec">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1>Academics</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                        <h3>Syllabus</h3> 
                        <p> The school follows the syllabus prescribed by the Central Board of Secondary Education, New Delhi. The medium of instruction is English. The scheme assesses scholastic and co-scholastic aspects.</p>
                        <h3>Subjects taught:</h3> 
                        <p> English, Hindi, Malayalam, Mathematics, Environmental Studies, Science, Social Science, Information Technology, Communicative English, General Knowledge and Moral Science.</p>
                        </div>
                    </div> 
                </div>
            </div>
             <Footer />
        </>
    );
}

export default Academics;
