import React, { useEffect, useState } from 'react'
import Header from '../Component/Header'
import HomeSlider from '../Component/HomeSlider'
import News from '../Component/News'
import Events from '../Component/Events' 
import TopperX from '../Component/TopperX'
import TopperXII from '../Component/TopperXII'
import Gallery from '../Component/Gallery'
import Message from '../Component/Message'
import Footer from '../Component/Footer'
import Birthday from '../Component/Birthday'  
 import OnlineReg from'../Component/OnlineReg'
 import Notification from'../Component/Notification'
const Home = () => {
     useEffect(() => {
          document.title = 'Home -  Jai Rani Public School, Balussery';  
      }, []);  
const[changeClassVal, setChangeVal] = useState("1");
const changeClass1 = () => {
     setChangeVal("1");
}
const changeClass2 = () => {
     setChangeVal("2");
}
useEffect(()=>{
    
}, [changeClassVal])


     return (
          <>
             <OnlineReg/>
             <Notification/>
               <Header />
               <div className="homeslider">
                    <HomeSlider />
                    <div className='slider-text'>
                         <h1>  EDUCATION HAS LIMIT BUT LEARNING NOT! <span>BE A GOOD LEARNER LIFELONG</span></h1>
                    </div>
               </div>
               <div className='main-background'>
               <div className="aboutsec">
                    <div className='container'>
                         <div className='row'>
                              <div className='col-md-12'>  

                                             <div className='title'>
                                                  <h2>welcome to</h2>
                                                  <p>jai rani subs public school,<br />balussery kerala</p>
                                                  <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/JRPSB/public/Images/line.png" className="img-fluid" />
                                             </div> 
                                        <div className="aboutdesc">
                                             <div className='button'>
                                                  <button className='active'>Parents</button>
                                                  <button>Students</button>
                                             </div>
                                             <div className='four-blk'>
                                                  <div>
                                                       <div className='four-blk-icon'>
                                                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/JRPSB/public/Images/admission.gif" />
                                                       </div>
                                                       <h5>Admissions</h5>
                                                  </div>
                                                  <div>
                                                       <div className='four-blk-icon'>
                                                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/JRPSB/public/Images/parent-portal.gif" />
                                                       </div>
                                                       <h5>Parent Portal</h5>
                                                  </div>
                                                  <div>
                                                       <div className='four-blk-icon'>
                                                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/JRPSB/public/Images/form.gif" />
                                                       </div>
                                                       <h5>Forms</h5>
                                                  </div>
                                                  <div>
                                                       <div className='four-blk-icon'>
                                                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/JRPSB/public/Images/press.gif" />
                                                       </div>
                                                       <h5>Press</h5>
                                                  </div>
                                             </div>
                                        </div>
                                   
                                   <div className="aboutsecscddesc">
                                        <div className="maintitle">
                                             <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/JRPSB/public/Images/emblem.png" />
                                             <h2>about the school</h2>
                                        </div>
                                        <p>Jai Rani SUBS Public School is a Catholic Institution established and managed by Sisters of the Adoration of the Blessed Sacrament (SUBS) of Thamarassery Province. This congregation was founded in 1908 by the Servant of God Bishop Thomas Kurialassery. Besides being a great devotee of the Eucharistic Lord, he was a great educationalist and reformer of the society. We pursue the spirit and Charisma of our founder in imparting progressive and quality education suited to the changing needs and aspirations of our country.</p>

                                        <p>It is a co-educational institution affiliated to the Central Board of Secondary Education (CBSE) New Delhi and follows the text books and guidelines of the National Council of Educational Research and Training (NCERT).</p>
                                    
                                        <p>Jai Rani is far and wide renowned as an institution of unparalleled academic standards and exceptional co-curricular training. The school has a friendly and outstanding faculty to bring up the youth buds of future generation. We provide our young people with challenging and inspiring learning opportunities that facilitate each of them to grow in confidence and develop attitudes and skills towards the challenges they face in today's world. We are proud of promoting faith in God and traditional values such as honesty, respect and politeness, as well as enjoying the latest technology, teaching methods and resources. Above all, we want our children to be happy, have confidence and possess a real enthusiasm for life.</p>
                                        </div>
                              </div>
                         </div>
                    </div>
               </div>
               <div className="aboutmidsec padding-lr">
                    <div className="container-fluid fulwidth">
                         <div className="row">
                              <div className="col-lg-7">
                                   <div className="maintitle">
                                        <h2>latest events</h2>
                                   </div>
                                   <Events />
                              </div>
                              <div className="col-lg-5">
                                   <div className="maintitle">
                                        <h2>latest news</h2>
                                   </div>
                                   <News />
                              </div>

                         </div>
                    </div>
               </div>

               <div className="homemsg">
                    <div className="container">
                         <div className="row">
                              <div className="col-xl-12 col-lg-12">
                                   <div className="maintitle">
                                        <h2>Message section</h2>
                                   </div>
                                   <Message />

                              </div>
                         </div>
                    </div>
               </div>
               <div className="toopersec">
                    <div className="toopersecbg">
                         <div class="stripClass">
                              <div className={`classtopper ${changeClassVal==="1" && "active"}`} onClick={changeClass1}> 
                                   12TH
                              </div>
                              <div className={`classtopper ${changeClassVal==="2" && "active"}`}  onClick={changeClass2}>
                              10TH 
                              </div>
                         </div>
                         <div className="container fulwidth">
                              <div className="row">
                                   <div className="col-lg-6 col-md-6 topper12bx">  
                                   <div className="maintitle"> 
                                        <h2>Toppers Section</h2>
                                   </div>
                                   {changeClassVal === "1" &&  <TopperXII />}
                                   {changeClassVal === "2" &&  <TopperX />} 
                                   </div>
                                   
                                   <div className="col-lg-2 col-md-2"> 
                                   </div>
                                   <div className="col-lg-4 col-md-4"> 
                                   <div className="maintitle"> 
                                        <h2>Birthdays Section</h2>
                                   </div>
                                       <Birthday />
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
               <div className="homegallery padding-lr">
                    <div className="container-fluid fulwidth">
                         <div className="row">
                              <div className="col-lg-12">
                                   <div className="maintitle">
                                        <h2>school in images</h2> 
                                   </div>
                                   <Gallery />
                                    
                              </div>
                         </div>
                    </div>
               </div>
               </div>
               <Footer />
          </>

     )
}

export default Home
